import React from "react";
import DateTimeDisplay from "../DateTimeDisplay/DateTimeDisplay";
import { useCountdown } from "../../hooks/useCountdown";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Bid Expired</span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, textCountdownPST }) => {
  return (
    <div className="show-counter">
      <div className="" style={{ display: "flex" }}>
        <span
          className="hour-image"
          style={{ width: "20%", margin: "auto", paddingLeft: "25px" }}
        >
          <img
            alt="bid-my-ride"
            src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Hourglass.gif"
            height={"45px"}
          />
        </span>
        <div className="countdown-link">
          {days > 0 && (
            <>
              <DateTimeDisplay value={days} type={"Days"} isDanger={false} />
              <p>:</p>
            </>
          )}
          {hours > 0 && (
            <>
              <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
              <p>:</p>{" "}
            </>
          )}
          <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
          <p>:</p>
        </div>
        <span className="hour-image" style={{ width: "20%", margin: "auto" }}>
          <img
            alt="bid-my-ride"
            src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Hourglass.gif"
            height={"45px"}
          />
        </span>
      </div>

      <div className="countdown-text-container">
        <p className="countdown-header-text">Limited Time!</p>
        <p className="countdown-subHeader-text">
          <span>Submit your best bid by </span>
          <span>{textCountdownPST}</span>
        </p>
      </div>
    </div>
  );
};

const CountDownTimer = ({ targetDate }) => {
  const { textCountdownPST } = targetDate;
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        textCountdownPST={textCountdownPST}
      />
    );
  }
};

export default CountDownTimer;