import React from "react";

import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselComponent.scss";

const CarouselComponent = ({ images }) => {
  return (
    <>
      <div className="index-page-banner-container">
        <div className="carousel-slideshow-container">
          <Carousel autoPlay={false} showThumbs={false} showIndicators={false} interval={0} showArrows={false}>
            {images.map((image, index) => {
              return (
                <div className="carousel-slide-content" key={index}>
                  <img alt={image.alt} src={image.srcPath} />
                </div>
              );
            })}
          </Carousel>
          <div className="logo-content-box">
            <div className="slider-text-container">
              <div className="gcLogo">
                <img alt="bid-my-ride" src="https://assets.s3.amazonaws.com/images/front-end/new-landing/BidMyRide-Logo.png" height={"45px"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselComponent;
