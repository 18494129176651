import React from "react";
import "./IndexPage.scss";
import { MegaTitle, CustomHeader, ImageSrcSet, PrimaryHeader, MarginBottom, Paragraph } from "../Elements";
import { SignUp, AboutSteps, FAQs, OperatorForm, Footer } from "../Templates";
import { Header, ContainerFluid, FlexRow, Container } from "../Layouts";

const IndexPage = () => {
  return (
    <>
      <div className="bid-main-container">
        <MarginBottom className="mb-space-2" />
        <Container>
          <Header />
          <div className="bid-header-container">
            <div className="bid-image-desc">
              <MegaTitle className="mega-title">Bid on rides in your area daily.</MegaTitle>
              <CustomHeader className="custom-para">We send 100’s of rides every week to operators across the U.S to bid on all rides and vehicle types. Join for free today.</CustomHeader>
              <SignUp />
            </div>
            <div className="bid-image-container">
              <ImageSrcSet alt="logo" srcSet="HeaderHero/header-hero" className="hero-img" />
            </div>
          </div>
        </Container>
        <MarginBottom className="mb-space-4" />

        <Container>
          <PrimaryHeader className="text-medium">
            Finding rides shouldn’t be hard.
            <br /> Sit back and get rides sent to you every day.
          </PrimaryHeader>
          <MarginBottom className="mb-space-1" />
          <AboutSteps />
          <MarginBottom className="mb-space-3" />
          <FAQs />
          <MarginBottom className="mb-space-2" />
        </Container>
        <ContainerFluid>
          <Container>
            <FlexRow>
              <div className="operator-form-desc">
                <div className="container-xl">
                  <PrimaryHeader className="text-medium">
                    Finish your <br /> onboarding as a <br />
                    BidMyRide Operator <br />
                    today.
                  </PrimaryHeader>
                  <Paragraph className="support-text">
                    Need some help? Call 415.993.5075 <br /> or email support@bidmyride.com <br /> for assistance.
                  </Paragraph>
                </div>
                <div className="container-md">
                  <PrimaryHeader className="text-medium ">Finish your onboarding as a BidMyRide Operator today.</PrimaryHeader>
                  <Paragraph className="support-text">Need some help? Call 415.993.5075 or email support@bidmyride.com for assistance.</Paragraph>
                </div>
              </div>
              <div className="operator-form">
                <OperatorForm />
              </div>
            </FlexRow>
          </Container>
        </ContainerFluid>

        <div className="contact-information-container">
          <span>Need some help? Call 415.993.5075 or email support@bidmyride.com for assistance.</span>
        </div>

        {/*     <Container>
          <div className="bid-operator-review">
            <PrimaryHeader className="primary-text text-medium">
              We’ve helped several Operators/reviews/ratings
            </PrimaryHeader>
            <MarginBottom className="mb-space-1" />
            <div className="bid-review-container"></div>
          </div>
        </Container> */}
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
