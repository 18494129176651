import React, { useState } from "react";
import "./Accordian.scss";
import { Paragraph } from "../../Typography";
import { BiPlus, BiMinus } from "react-icons/bi";
import FlexRow from "./../../../Layouts/FlexRow/index";

const Accordian = ({ faq, index, toggleFAQ, indexNum, faqLength }) => {
  const [isActive, setActive] = useState(false);

  const _toggleClass = () => {
    console.log(faq.open);
    if (faq.open) {
      setActive(false);
    }
  };

  return (
    <div
      className={"faq " + (faq.open ? "open collapsed " : " ")}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      key={index}
      onClick={() => {
        toggleFAQ(index);
        _toggleClass();
      }}
    >
      <div
        className=""
        style={{ display: "flex", flexDirection: "row", width: "100%" }}
      >
        <div className="expand-icons">
          <div
            style={{ position: "absolute", top: "10px" }}
            className={
              !isActive && faq.open
                ? "plus-minus-toggle"
                : "plus-minus-toggle collapsed"
            }
          ></div>
        </div>

        <div style={{ flexRow: "1", width: "100%" }}>
          <div className="faq-question">{faq.question}</div>
          <div className="faq-answer">
            <div className="para-faq">
              {faq.answer}
              {faq.answer2 && (
                <>
                  <br />
                  <br />
                  {faq.answer2}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {indexNum != faqLength && (
        <hr className="hr-line" style={{ padding: "0" }} />
      )}
    </div>
  );
};

export default Accordian;
