import React from "react";
import { Routes, Route } from "react-router-dom";
import { routePaths } from "../../routes/routes";
import IndexPage from "../IndexPage/IndexPage";
import BidMyRidePage from "../BidMyRidePage/BidMyRidePage";
import OperatorPage from "../OperatorPage/OperatorPage";
import ManualRideForm from "../ManualRideForm/ManualRideForm";

const RouteContainer = () => {
  return (
    <Routes>
      <Route path={routePaths.root} element={<IndexPage />} />
      <Route path={routePaths.bidMyRide} element={<BidMyRidePage />} />
      <Route path={routePaths.operatorPage} element={<OperatorPage />} />
       <Route path={routePaths.manualBid} element={<ManualRideForm />} />
    </Routes>
  );
};

export default RouteContainer;
