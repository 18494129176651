import React from "react";
import "./ErrorDisplay.scss";

const ErrorDisplay = (props) => {
  const { className, style, children } = props;
  return (
    <p className={`error-text ${className}`} style={style} role="alert">
      {children}
    </p>
  );
};

export default ErrorDisplay;
