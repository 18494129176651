import React, { useEffect, useState } from "react";
import "./SignUp.scss";
import { InputText, Button, Form } from "../../Elements";
import { useForm } from "react-hook-form";
import ErrorDisplay from "../ErrorDisplay";
import { API_ENDPOINTS } from "../../../constants/constants";
import useApi from "../../../hooks/useAPI";

const SignUp = (props) => {
  const { buttonColor, inputClass, parentClass } = props;
  const [errorMsg, setErrorMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { createEmailWithZipCode, error, data } = useApi();

  const onSubmit = (data) => {
    console.log("onsubmit 1");
    createEmailWithZipCode(API_ENDPOINTS.signUp, "POST", data);
  };
  useEffect(() => {
    console.log("error", error);
    setErrorMsg(error);
    setSuccessMsg("");
  }, [error]);

  useEffect(() => {
    setErrorMsg("");
    setSuccessMsg(data);
  }, [data]);

  return (
    <Form className="bid-operator-signup" onSubmit={handleSubmit(onSubmit)}>
      <div className="bid-signup-fields">
        <div className={`input-box ${parentClass}`}>
          <InputText
            className={inputClass}
            type="text"
            placeholder="Email"
            register={register("contact_email", {
              required: {
                value: true,
                message: "Email address is required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email",
              },
            })}
            aria-invalid={errors.contact_email ? "true" : "false"}
          />
          {errors.contact_email && (
            <ErrorDisplay className="error-red">
              {errors.contact_email?.message}
            </ErrorDisplay>
          )}
        </div>
        <div className={`input-box ${parentClass}`}>
          <InputText
            className={inputClass}
            type="text"
            placeholder="Zipcode"
            maxLength="12"
            register={register("zipcode", {
              required: "ZipCode is required",
              minLength: {
                value: 5,
                message: "ZipCode Code should be 5 digit",
              },
            })}
            aria-invalid={errors.zipcode ? "true" : "false"}
          />
          {errors.zipcode && (
            <ErrorDisplay className="error-red">
              {errors.zipcode?.message}
            </ErrorDisplay>
          )}
        </div>
      </div>

      <Button type="submit" className="bidBtn blue">
        Sign Up as Operator for Free
      </Button>

      <div className="server-error">
        {successMsg?.length > 0 && (
          <ErrorDisplay style={{ color: "green" }}>{successMsg}</ErrorDisplay>
        )}
        {errorMsg?.length > 0 && (
          <ErrorDisplay className="error-red">{errorMsg?.message}</ErrorDisplay>
        )}
      </div>
    </Form>
  );
};

export default SignUp;
