import React from "react";
import "./CustomHeader.scss";

const CustomHeader = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`default-text ${className}`} style={style}>
      {children}
    </div>
  );
};

export default CustomHeader;
