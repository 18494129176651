import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../utils/useQuery.js";

import ReCAPTCHA from "react-google-recaptcha";

import CountDownTimer from "../CountDownTimer/CountDownTimer";
import Notification from "../Notification/Notification";

import "./BidMyRideForm.scss";

const BidMyRideForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  let query = useQuery();

  const [bidMyRideErrors, setBidMyRideErrors] = useState([]);
  const [countDownData, setCountDownData] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    textCountdownPST: 0,
  });

  console.log("errors", errors);

  const onSubmit = async (data) => {
    console.log("formData", data);
    if (errors && Object.keys(errors).length === 0) {
      setBidMyRideErrors([]);
      try {
        await fetch("https://www.bidmyride.com/api/bids", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(data),
        });
        Notification.sucess("Form Submitted Successfully.");
      } catch (error) {
        Notification.error("Server Error");
        console.log("e", error);
        bidMyRideErrors([error]);
      }
    }
  };

  const handleSetRecaptcha = (captchaData) => {
    console.log("captchaData", captchaData);
    setValue("g-recaptcha-response", captchaData);
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleInsuranceLimit = (event) =>
    setValue(
      "insurance_limit",
      addCommas(removeNonNumeric(event.target.value))
    );

  const addHypen = (num) =>
    num
      .toString()
      .replace(/(\d{0,3})\-?(\d{0,3})\-?(\d{0,6}).*/, "$1-$2-$3")
      .replace(/\-+$/, "")
      .replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, "$1-$2-$3");

  const formatPhoneNumber = (event) =>
    setValue("contact_phone", addHypen(event.target.value));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let response = await (
      await fetch(
        `https://www.bidmyride.com/api/countdown_timer/${
          query.get("bookingId") || ""
        }`
      )
    ).json();
    setCountDownData({
      ...countDownData,
      ...response,
    });
  };

  const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Bid Expired</span>
      </div>
    );
  };

  const { days, hours, minutes, seconds } = countDownData;

  const isExpiredTimer =
    days == 0 && hours == 0 && minutes == 0 && seconds == 0;

  return (
    <>
      {isExpiredTimer ? (
        <ExpiredNotice />
      ) : (
        <CountDownTimer targetDate={countDownData} />
      )}

      {/* <CountDownTimer
        targetDate={{
          hours: 16,
          minutes: 53,
          seconds: 8,
          textCountdownPST: "Jan 06, 2023 05:27am",
        }}
      /> */}

      <form className="bid-my-ride-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-field">
          <label className="input-label" htmlFor="booking_id">
            BOOKING ID*
          </label>
          <input
            type="text"
            name="booking_id"
            readOnly={true}
            placeholder="8j45"
            {...register("booking_id", {
              required: false,
            })}
            value={query.get("bookingId") || ""}
          />
        </div>

        <div className="input-field">
          <label className="input-label tooltip-icon" htmlFor="price">
            ALL-IN PRICE FOR ENTIRE JOB($)*
            <span className="tooltiptext">
              (including gratuity, parking fees, tolls etc)
            </span>
          </label>
          <input
            type="number"
            placeholder="Example: 500"
            {...register("price", {
              required: "Price is required.",
              pattern: {
                value: /^([6-9]\d|\d{3,})$/,
                message: "Minimum price should be $60",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.price && <p>{errors.price.message}</p>}
        </div>

        <div className="input-field-checkbox">
          <label htmlFor="agree_checkbox" className="agree_checkbox_label">
            <input
              type="checkbox"
              placeholder="agree"
              {...register("agree", {
                required: "This field is required.",
              })}
              className="agree_checkbox_text"
            />
            I agree to honor this price for the next 14 days.
          </label>
        </div>
        <div className="error-field">
          {errors.agree && <p>{errors.agree.message}</p>}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="capacity">
            VEHICLE CAPACITY*
          </label>
          <input
            type="number"
            placeholder="Example: 48"
            {...register("capacity", {
              required: "Vehice capacity is required.",
              pattern: {
                value: /^[0-9]{1,8}$/,
                message: "Please enter 8 digits only.",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.capacity && <p>{errors.capacity.message}</p>}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="vehicle_year">
            VEHICLE YEAR*
          </label>
          <input
            type="number"
            placeholder="Example: 2018"
            {...register("vehicle_year", {
              required: "Vehicle Year is required.",
              pattern: {
                value: /^[0-9]{1,4}$/,
                message: "Please enter 4 digits only.",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.vehicle_year && <p>{errors.vehicle_year.message}</p>}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="vehicle_make_model">
            VEHICLE MAKE AND MODEL*
          </label>
          <input
            type="text"
            placeholder="Example: Cadillac Escalade, Grech Ford F550, MCI J Coach, IPhone Charger"
            {...register("vehicle_make_model", {
              required: "Vehicle make and model is required.",
              maxLength: {
                value: 50,
                message: "Max length is 50",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.vehicle_make_model && (
            <p>{errors.vehicle_make_model.message}</p>
          )}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="insurance_limit">
            AUTO LIABILITY INSURANCE LIMIT ($)*
          </label>
          <select
            {...register("insurance_limit", {
              required: "Auto liability insurance limit is required.",
            })}
          >
            <option value="">Please select</option>
            <option value="Under 300,000">Under 300,000</option>
            <option value="300,000 to 500,000">300,000 to 500,000</option>
            <option value="500,000 to 750,000">500,000 to 750,000</option>
            <option value="1 Million or more">1 Million or more</option>
          </select>
        </div>
        <div className="error-field">
          {errors.insurance_limit && <p>{errors.insurance_limit.message}</p>}
        </div>

        <div className="input-field textareaType">
          <label className="input-label" htmlFor="amenities">
            Amenities Included in Price
          </label>
          <textarea
            type="text"
            placeholder="Example: washroom, ice chest, WiFi, music, drinks, water, red carpet, champagne, car seat etc"
            {...register("amenities", {
              required: false,
            })}
          />
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="contact_name">
            CONTACT NAME*
          </label>
          <input
            type="text"
            placeholder=""
            {...register("contact_name", {
              required: "Contact name is required.",
              maxLength: {
                value: 50,
                message: "Max length is 50",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.contact_name && <p>{errors.contact_name.message}</p>}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="contact_email">
            Contact Email*
          </label>
          <input
            type="text"
            placeholder=""
            {...register("contact_email", {
              required: "Contact email is required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address.",
              },
            })}
          />
        </div>
        <div className="error-field">
          {errors.contact_email && <p>{errors.contact_email.message}</p>}
        </div>

        <div className="input-field">
          <label className="input-label" htmlFor="contact_phone">
            contact phone number*
          </label>
          <input
            type="text"
            placeholder=""
            {...register("contact_phone", {
              required: "Contact Phone number is required.",
              minLength: {
                value: 12,
                message: "Phone number should be 10 digits.",
              },
            })}
            onChange={formatPhoneNumber}
          />
        </div>
        <div className="error-field">
          {errors.contact_phone && <p>{errors.contact_phone.message}</p>}
        </div>

        <div className="form-item google-recaptcha">
          <ReCAPTCHA
            {...register("g-recaptcha-response", {
              required: "Please select the captcha.",
            })}
            name="g-recaptcha-response"
            sitekey="6LcpIdkjAAAAAC6dHQe1pgDZ-TTHiXjh6mm-85qg"
            onChange={handleSetRecaptcha}
          />
        </div>
        <div className="error-field">
          {errors["g-recaptcha-response"] && (
            <p>{errors["g-recaptcha-response"].message}</p>
          )}
        </div>

        {bidMyRideErrors.length > 0 && (
          <div className="error-field">
            {bidMyRideErrors.map((error, index) => {
              return <p>{error}</p>;
            })}
          </div>
        )}

        <div className="submit-container">
          <input type="submit" value="Submit Bid!" />
        </div>
      </form>
    </>
  );
};

export default BidMyRideForm;