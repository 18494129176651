import React from "react";

import BidMyRideForm from "../BidMyRideForm/BidMyRideForm";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import Footer from "../Footer/Footer";

import "./BidMyRidePage.scss";

import { useQuery } from "../../utils/useQuery.js";

const BidMyRidePage = () => {
  let query = useQuery();
  return (
    <>
      <CarouselComponent
        images={[
          {
            srcPath:
              "https://assets.s3.amazonaws.com/images/front-end/new-landing/SubmissionBanner.jpg",
            alt: "bid-my-ride",
          },
        ]}
      />
      <div className="bid-my-ride-page">
        <div className="map-section-wrap">
          <div className="map-section-container">
            <div className="map-section-container-title">
              <h4>Please submit your best bid for this ride: </h4>
            </div>
            <div className="map-section-data">
              <p className="map-section-each-row">
                <span className="map-section-label">Date</span>
                <span className="map-section-description">
                  {query.get("date") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Time</span>
                <span className="map-section-description">
                  {query.get("time") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Pickup Address</span>
                <span className="map-section-description">
                  {query.get("pickUpAddress") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Dropoff Address</span>
                <span className="map-section-description">
                  {query.get("dropOffAddress") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">
                  Hours (Client pickup to dropoff)
                </span>
                <span className="map-section-description">
                  {query.get("hours") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Service Type</span>
                <span className="map-section-description">
                  {query.get("serviceType") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Passengers</span>
                <span className="map-section-description">
                  {query.get("passengers") || ""}
                </span>
              </p>
              <p className="map-section-each-row">
                <span className="map-section-label">Vehicle Type</span>
                <span className="map-section-description">
                  {query.get("vehicleType") || ""}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="bid-my-form-container">
          <BidMyRideForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BidMyRidePage;
