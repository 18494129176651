import React from "react";
import "./Paragraph.scss";

const Paragraph = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`paragraph ${className}`} style={style}>
      {children}
    </div>
  );
};

export default Paragraph;
