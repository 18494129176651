import React from "react";
import "./Footer.scss";
import { useForm } from "react-hook-form";
import { InputText, Button, Form } from "../../Elements";
import ErrorDisplay from "../ErrorDisplay";
import { API_ENDPOINTS } from "../../../constants/constants";
import useApi from "../../../hooks/useAPI";

const Footer = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const { createEmailWithZipCode } = useApi();

  const onSubmit = (data) => {
    createEmailWithZipCode(API_ENDPOINTS.signUp, "POST", data);
  };

  return (
    <div className="footer-component-new">
      <div className="footer-content">
        <h1 className="footer-header">Ready, Set, Bid.</h1>

        <Form className="bid-footer-signup" onSubmit={handleSubmit(onSubmit)}>
          <div className="bid-footer-fields">
            <div className="footer-input-container">
              <InputText
                className="blue-textBox"
                type="text"
                placeholder="Email"
                register={register("footerEmail", {
                  required: {
                    value: true,
                    message: "Email address is required",
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email",
                  },
                })}
                aria-invalid={errors.footerEmail ? "true" : "false"}
              />
              {errors.footerEmail && (
                <ErrorDisplay className="error-display">
                  {errors.footerEmail?.message}
                </ErrorDisplay>
              )}
            </div>
            <div className="footer-input-container">
              <InputText
                className="blue-textBox"
                type="text"
                placeholder="Zipcode"
                maxLength="12"
                register={register("footerZipCode", {
                  required: "ZipCode is required",
                  minLength: {
                    value: 5,
                    message: "ZipCode Code should be 5 digit",
                  },
                })}
                aria-invalid={errors.footerZipCode ? "true" : "false"}
              />
              {errors.footerZipCode && (
                <ErrorDisplay className="error-display">
                  {errors.footerZipCode?.message}
                </ErrorDisplay>
              )}
            </div>
          </div>
          <Button type="submit" className="bidBtn white">
            Sign Up as Operator for Free
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Footer;
