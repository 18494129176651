import React, { useState, useEffect } from "react";
import "./OperatorForm.scss";
import { InputText, Button, Form } from "../../Elements";
import { useForm } from "react-hook-form";
import ErrorDisplay from "../ErrorDisplay";
import ReCAPTCHA from "react-google-recaptcha";
import useApi from "../../../hooks/useAPI";
import { API_ENDPOINTS } from "../../../constants/constants";

const OperatorForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const { createOnboardingForm, getVehicleList, vehicleList, error, data } =
    useApi();

  const [file, setFile] = useState();
  const [lang, setLang] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [errorOnboard, setErrorOnboard] = useState();
  const [successOnboard, setSuccessOnboard] = useState();

  useEffect(() => {
    setErrorOnboard(error);
    setSuccessOnboard("");
  }, [error]);

  useEffect(() => {
    setErrorOnboard("");
    setSuccessOnboard(data);
  }, [data]);

  useEffect(() => {
    setVehicleData(vehicleList);
  }, [vehicleList]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleCheckBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setLang((prev) => [...prev, value]);
    } else {
      setLang((prev) => prev.filter((x) => x !== value));
    }
  };

  // console.log(vehicleData);

  const handleSetRecaptcha = (captchaData) => {
    console.log("captchaData", captchaData);
    setValue("g-recaptcha-response", captchaData);
  };

  const onSubmit = (data) => {
    console.log(data);
    data.package_document = file;
    createOnboardingForm(API_ENDPOINTS.onboardForm, "POST", data);
  };

  return (
    <Form className="bid-operator-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="bid-operator-fields">
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="ZipCode*"
            className="marginSpace"
            register={register("zipcode", {
              required: "ZipCode is required",
              minLength: {
                value: 5,
                message: "ZipCode Code should be 5 digit",
              },
            })}
            aria-invalid={errors.zipcode ? "true" : "false"}
            maxLength={12}
          />
          {errors.zipcode && (
            <ErrorDisplay>{errors.zipcode?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Company Legal Name*"
            className="marginSpace"
            register={register("company_legal_name", {
              required: "CompanyName is required",
            })}
            aria-invalid={errors.company_legal_name ? "true" : "false"}
          />
          {errors.company_legal_name && (
            <ErrorDisplay>{errors.company_legal_name?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Public Display Name (DBA)*"
            className="marginSpace"
            register={register("public_display_name", {
              required: "DBA is required",
            })}
            aria-invalid={errors.public_display_name ? "true" : "false"}
          />
          {errors.public_display_name && (
            <ErrorDisplay>{errors.public_display_name?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact First Name*"
            className="marginSpace"
            register={register("contact_first_name", {
              required: "First Name is required",
            })}
            aria-invalid={errors.contact_first_name ? "true" : "false"}
          />
          {errors.contact_first_name && (
            <ErrorDisplay>{errors.contact_first_name?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact Last Name*"
            className="marginSpace"
            register={register("contact_last_name", {
              required: "Last Name is required",
            })}
            aria-invalid={errors.contact_last_name ? "true" : "false"}
          />
          {errors.contact_last_name && (
            <ErrorDisplay>{errors.contact_last_name?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact Email*"
            className="marginSpace"
            register={register("contact_email", {
              required: {
                value: true,
                message: "Email address is required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Email address is required",
              },
            })}
            aria-invalid={errors.contact_email ? "true" : "false"}
          />
          {errors.contact_email && (
            <ErrorDisplay>{errors.contact_email?.message}</ErrorDisplay>
          )}
        </div>
        <div className="input-error-wrapper">
          <InputText
            type="text"
            placeholder="Preferred Contact Phone Number*"
            className="marginSpace"
            maxLength={12}
            minLength={10}
            register={register("contact_phone_number", {
              required: "Contact Number is required",
              minLength: {
                value: 10,
                message: "Contact Number should be 10 digit",
              },
            })}
            aria-invalid={errors.contact_phone_number ? "true" : "false"}
          />
          {errors.contact_phone_number && (
            <ErrorDisplay>{errors.contact_phone_number?.message}</ErrorDisplay>
          )}
        </div>

        <div className="input-error-wrapper">
          <div className="select-width">
            <i
              className="arrow drop-arrow"
              style={{
                position: "absolute",
                top: "25%",
                right: "5%",
                cursor: "pointer",
              }}
            ></i>

            <select
              defaultValue={"Auto-Liability Insurance Limit"}
              className="input-box select-hover"
              {...register("auto_liability_insurance_limit", {
                required: "Select Insurance Limit",
              })}
            >
              <option
                value=""
                disabled={watch("Auto-Liability Insurance Limit ($)*")}
              >
                Auto-Liability Insurance Limit ($)*
              </option>
              <option value="Under 300,000">Under 300,000</option>
              <option value="300,000 to 500,000">300,000 to 500,000</option>
              <option value="500,000 to 750,000">500,000 to 750,000</option>
              <option value="1 Million or More">1 Million or More</option>
            </select>
            {errors.auto_liability_insurance_limit && (
              <ErrorDisplay>
                {errors.auto_liability_insurance_limit?.message}
              </ErrorDisplay>
            )}
          </div>
        </div>

        {/*   <DownloadPDF
          style={{ padding: "0px", alignItems: "center", height: "3rem" }}
          className="download-pdf"
          fileName="dummy.pdf"
          descriptionL1="Download our Requirements Package"
          descriptionL2=" Fill it in, and attach it below*"
        />
        <div className="input-error-wrapper">
          <div className="input-box" style={{ padding: "0.5rem" }}>
            <FlexRow>
              <div className="icon-text">
                <p className="icon-container">
                  <span className="icon-attach"></span>
                </p>
                <input
                  type="file"
                  id="fileupload"
                  style={{ display: "none" }}
                  name="package_document"
                  {...register("package_document", {
                    required: " Please Attach Requirements Package",
                  })}
                  onChange={handleFileChange}
                />
                <label
                  style={{ padding: "2px", cursor: "pointer", width: "83%" }}
                  htmlFor="fileupload"
                >
                  {file
                    ? `${file.name} - ${file.type}`
                    : "Attach Requirements Package (PDF)*"}
                </label>
              </div>
            </FlexRow>
          </div>
          {errors.package_document && (
            <ErrorDisplay>{errors.package_document?.message}</ErrorDisplay>
          )}
        </div> */}
        <div className="input-error-wrapper ">
          <div className="inputDiv marginSpace select-hover">
            <div className="form-checkbox ">
              <div className="title">
                What vehicles do you have in your fleet?
              </div>
              <div className="form-checkboxs">
                <ul className="checkbox checkbox-flex">
                  {vehicleData.map((x, i) => (
                    <li
                      key={i}
                      style={{
                        alignItems: "baseline",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="li-flex">
                        <input
                          type="checkbox"
                          value={x.id}
                          onChange={handleCheckBox}
                          className="input-checkbox"
                          {...register("vehicle_type_ids", {
                            required: "Please select Vehicle",
                          })}
                        />
                        <label className="form-label">{x.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {errors.vehicle_type_ids && (
            <ErrorDisplay>{errors.vehicle_type_ids?.message}</ErrorDisplay>
          )}
        </div>
      </div>

      <div
        id="captcha"
        style={{ marginBottom: "0px", padding: "0px", width: "274px" }}
      >
        <ReCAPTCHA
          className="g-recaptcha"
          {...register("g-recaptcha-response", {
            required: "Please select the captcha.",
          })}
          name="g-recaptcha-response"
          sitekey="6LcpIdkjAAAAAC6dHQe1pgDZ-TTHiXjh6mm-85qg"
          onChange={handleSetRecaptcha}
        />
        {errors["g-recaptcha-response"] && (
          <ErrorDisplay style={{ padding: "0px", margin: "0.2rem" }}>
            {errors["g-recaptcha-response"].message}
          </ErrorDisplay>
        )}
      </div>

      <Button type="submit" className="bidBtn white">
        Sign Up as Operator for Free
      </Button>

      <div className="server-error">
        {successOnboard?.length > 0 && (
          <ErrorDisplay style={{ color: "green" }}>
            {successOnboard}
          </ErrorDisplay>
        )}
        {errorOnboard?.length > 0 && (
          <ErrorDisplay className="error-red">
            {errorOnboard?.message}
          </ErrorDisplay>
        )}
      </div>
    </Form>
  );
};

export default OperatorForm;
