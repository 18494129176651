import React from "react";
import "./PrimaryHeader.scss";

const PrimaryHeader = (props) => {
  const { className, style, title, color, children } = props;
  return (
    <div className={`primary-text ${className}`} style={style}>
      {children}
    </div>
  );
};

export default PrimaryHeader;
