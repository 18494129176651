import React from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import "./TypeAheadFormField.scss";
import "bootstrap/scss/bootstrap.scss";

export default class TypeAheadFormField extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.google && !prevProps.google) {
      this.service = new this.props.google.maps.places.PlacesService(
        this.instance
      );
    }
  }

  // disable cache to prevent a bug the prevents the options from being shown sometimes
  render() {
    const { google } = this.props;
    return (
      <>
        
        <AsyncTypeahead
          filterBy={(option, props) => true}
          useCache={false}
          autoFocus={!!this.props.autoFocus}
          inputProps={this.props.inputProps}
          name={this.props.name}
          id={this.props.id}
          className={(this.props.className || "") + " typeAheadContainer"}
          placeholder={this.props.placeholder}
          isLoading={this.state.isLoading}
          onInputChange={this.props.onInputChange}
          onChange={(selectedItem) => {
            this.props.onSelected(selectedItem[0]);
            if (
              this.props.useGoogle &&
              selectedItem.length > 0 &&
              selectedItem[0].isAirport
            ) {
              this.props.setPlaceValid(true);
            } else if (
              this.props.useGoogle &&
              this.service &&
              this.service.getDetails &&
              selectedItem.length > 0
            ) {
              // filter out place types that are not specific enough route, locality, political
              this.service.getDetails(
                { placeId: selectedItem[0].placeId, fields: ["types"] },
                (place, status) =>
                  status === google.maps.places.PlacesServiceStatus.OK
                    ? this.props.setPlaceValid(
                        !(
                          place.types.includes("route") ||
                          place.types.includes("locality") ||
                          place.types.includes("political")
                        )
                      )
                    : "do-nothing"
              );
            }
          }}
          labelKey={(option) => `${option.display}`}
          autofill="off"
          onSearch={(query) => {
            this.setState({ isLoading: true });
            if (this.props.onSearch) {
              this.props.onSearch(query, this.props.id, () =>
                this.setState({ isLoading: false })
              );
            }
          }}
          options={this.props.queryResults}
        />
      </>
    );
  }
}
