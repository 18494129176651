import React from "react";

import OperatorForm from "../OperatorForm/OperatorForm";
import CarouselComponent from "../CarouselComponent/CarouselComponent";

import "./OperatorPage.scss";


const OperatorPage = () => {
  return (
    <>
      <CarouselComponent
        images={[
          {
            srcPath: "https://assets.s3.amazonaws.com/images/front-end/new-landing/SubmissionBanner.jpg",
            alt: "bid-my-ride",
          },
        ]}
      />
      <div className="operator-page">
        <div className="operator-form-container">
          <OperatorForm />
        </div>
      </div>
    </>
  );
};

export default OperatorPage;
