import axios from "axios";
import { API_ENDPOINTS } from "../constants/constants";

export const makeApiCall = (path, method, parameters) => {
  let apipath = API_ENDPOINTS.baseURL + path;
  if (method === "GET") {
    return axios.get(apipath + (parameters ? parameters : ""));
  } else if (method === "POST") {
    return axios.post(apipath, parameters ? parameters : {}, {
      headers: { "content-type": "application/json" },
    });
  } else if (method === "PUT") {
    return axios.put(apipath, parameters ? parameters : {}, {
      headers: { "content-type": "application/json" },
    });
  }
};
