import "./App.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import RouteContainer from "./components/RouteContainer/RouteContainer";
import { Footer } from "./components/Templates";

import "bootstrap/scss/bootstrap.scss";

function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <RouteContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
