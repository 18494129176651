import React from "react";
import SearchForm from "../SearchForm/SearchForm";

import "./ManualRideForm.scss";

const ManualRideForm = () => {
  return (
    <>
      <div className="searchForm">
        <SearchForm />
      </div>
    </>
  );
};

export default ManualRideForm;
