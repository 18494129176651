import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import DatePicker from "react-datepicker";
import moment from "moment";

import Notification from "../Notification/Notification";
import TypeAheadFormField from "../TypeAheadFormField/TypeAheadFormField";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import "./SearchForm.scss";

const zeroPad = (n) => ("0" + n).slice(-2);

const SearchForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [queryResults, setQueryResults] = useState([]);
  const [formInputs, setFormInputs] = useState({
    ride_date: null,
  });

  const tConvert = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  };

  const onSubmit = async (data) => {
    const formPostData = {
      pickup_time: tConvert(zeroPad(data.hour) + ":" + zeroPad(data.minute)),
      ride_date: moment(data.ride_date).format("MM/DD/YYYY"),
      pax: data.passenger,
      service_type: data.service_type,
      pickup_place: data.pickup_place.display,
      dropoff_place: data.dropoff_place.display,
      email: data.contact_email,
    };

    if (errors && Object.keys(errors).length === 0) {
      try {
        await fetch("https://www.bidmyride.com/api/operator_bid_emails/manual ", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(formPostData),
        });
        Notification.sucess("Form Submitted Successfully.");
      } catch (error) {
        Notification.error("Server Error");
        console.log("e", error);
      }
    }
  };

  const onTypeaheadSearch = (query, id, finished) => {
    let googleResults = [],
      airportResults = [],
      googleFinished = false,
      airportFinished = query.length < 2;
    const google = window.google;

    const finishSearch = () => {
      if (googleFinished && airportFinished) {
        console.log("googleResults", googleResults, "airportResults", airportResults);
        setQueryResults([...airportResults, ...googleResults]);
        finished();
      }
    };

    const googleSuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        console.log(`google place api error: ${status}`);
        googleFinished = true;
        finished();
        return;
      }

      googleResults = predictions
        .filter((res) => !res.types.includes("locality") && !res.types.includes("airport"))
        .map((res) => ({
          placeId: res.place_id,
          types: res.types,
          display: res.description,
        }));
      googleFinished = true;
      finishSearch();
    };

    const airportSuggestions = (data) => {
      airportResults = data.slice(0, 1).map((res) => ({
        placeId: res.id,
        display: res.text,
        is_airport: true,
      }));
      airportFinished = true;
      finishSearch();
    };

    new google.maps.places.AutocompleteService().getPlacePredictions({ input: query, types: [] }, googleSuggestions);
    // fetch(`/services/location/airports?n=${encodeURIComponent(query)}`)
    //   .then((res) => res.json())
    //   .then(airportSuggestions);
    airportSuggestions([]);
  };

  const addHypen = (num) =>
    num
      .toString()
      .replace(/(\d{0,3})\-?(\d{0,3})\-?(\d{0,6}).*/, "$1-$2-$3")
      .replace(/\-+$/, "")
      .replace(/(\d{3})\-?(\d{3})\-?(\d{4})/, "$1-$2-$3");

  const formatPhoneNumber = (event) => setValue("contact_phone", addHypen(event.target.value));

  const handleSetRecaptcha = (captchaData) => {
    console.log("captchaData", captchaData);
    setValue("g-recaptcha-response", captchaData);
  };

  return (
    <>
      <div className="bid-my-ride-search-form">
        <h3 className="form-title">Get the Best Deal</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-field">
            <label htmlFor="service_type">Service Type*</label>
            <select {...register("service_type", { required: "Service type is required." })}>
              <option value="" selected disabled hidden>
                Choose Service type
              </option>
              <optgroup label="Most Popular:">
                <option value="100">To Airport</option>
                <option value="99">From Airport</option>
                <option value="131072">Point-to-Point (one-way)</option>
                <option value="4096">Just Drive (hourly)</option>
              </optgroup>
              <optgroup label="Special Occasions:">
                <option value="16384">Prom / School Event</option>
                <option value="4">Wedding</option>
                <option value="64">Birthday / Anniversary</option>
                <option value="8192">Night-on-the-Town</option>
                <option value="128">Concert / Sporting Event</option>
                <option value="32">Bachelor(ette) Party</option>
                <option value="65536">Wine/Private Tour</option>
                <option value="256">Funeral</option>
              </optgroup>
            </select>
          </div>
          <div className="error-field">{errors.service_type && <p>{errors.service_type.message}</p>}</div>

          <div className="input-field">
            <label htmlFor="pickup_place">Pickup Location*</label>
            <TypeAheadFormField
              setPlaceValid={(valid) => console.log("event_location valid?", valid)}
              inputProps={{
                "data-hj-whitelist": true,
                className: "data-hj-whitelist",
                "data-test": "pickup-place",
              }}
              name="pickup_place"
              id="pickup_place"
              useGoogle={true}
              onSearch={onTypeaheadSearch}
              google={typeof window !== "undefined" && window.google}
              placeholder="Search Airport, City, Location"
              onPlaceQuery={(param) => console.log(param)}
              queryResults={queryResults || []}
              onSelected={(selectedItem) => setValue("pickup_place", selectedItem)}
              {...register("pickup_place", {
                required: "Pickup location is required.",
                maxLength: {
                  value: 100,
                  message: "Max length is 100",
                },
              })}
            />
          </div>
          <div className="error-field">{errors.pickup_place && <p>{errors.pickup_place.message}</p>}</div>

          <div className="input-field">
            <label htmlFor="dropoff_place">Dropoff Location*</label>
            <TypeAheadFormField
              setPlaceValid={(valid) => console.log("dropoff_place valid?", valid)}
              inputProps={{
                "data-hj-whitelist": true,
                className: "data-hj-whitelist",
                "data-test": "dropoff-place",
              }}
              name="dropoffPlace"
              id="dropoff_place"
              useGoogle={true}
              onSearch={onTypeaheadSearch}
              google={typeof window !== "undefined" && window.google}
              placeholder="Search Airport, City, Location"
              onPlaceQuery={(param) => console.log(param)}
              queryResults={queryResults || []}
              onSelected={(selectedItem) => setValue("dropoff_place", selectedItem)}
              {...register("dropoff_place", {
                required: "Dropoff location is required.",
                maxLength: {
                  value: 100,
                  message: "Max length is 100",
                },
              })}
            />
          </div>
          <div className="error-field">{errors.dropoff_place && <p>{errors.dropoff_place.message}</p>}</div>

          <div className="date-time-container">
            <div className="date-container">
              <div className="input-field">
                <label htmlFor="ride_date">Trip Date*</label>
                <DatePicker
                  className="form-control rbt-input-main rbt-input data-hj-whitelist"
                  name="ride_date"
                  placeholderText={"Select Date"}
                  minDate={new Date()}
                  selected={formInputs.ride_date}
                  onChange={(date) => {
                    setFormInputs({
                      ...formInputs,
                      ride_date: date,
                    });
                    setValue("ride_date", date);
                  }}
                  isClearable={false}
                  dateFormat="eee, MMMM do, yyyy"
                  autoComplete="off"
                />
                <input
                  value={formInputs.ride_date}
                  type="hidden"
                  {...register("ride_date", {
                    required: "Ride date is required.",
                  })}
                />
              </div>
            </div>

            <div className="time-container input-field">
              <div className="hour-container">
                <label htmlFor="hour">Hour*</label>
                <select {...register("hour", { required: true })}>
                  <option value="0">12 AM</option>
                  <option value="1" selected>
                    1 AM
                  </option>
                  <option value="2">2 AM</option>
                  <option value="3">3 AM</option>
                  <option value="4">4 AM</option>
                  <option value="5">5 AM</option>
                  <option value="6">6 AM</option>
                  <option value="7">7 AM</option>
                  <option value="8">8 AM</option>
                  <option value="9">9 AM</option>
                  <option value="10">10 AM</option>
                  <option value="11">11 AM</option>
                  <option value="12">12 PM</option>
                  <option value="13">1 PM</option>
                  <option value="14">2 PM</option>
                  <option value="15">3 PM</option>
                  <option value="16">4 PM</option>
                  <option value="17" selected>
                    5 PM
                  </option>
                  <option value="18">6 PM</option>
                  <option value="19">7 PM</option>
                  <option value="20">8 PM</option>
                  <option value="21">9 PM</option>
                  <option value="22">10 PM</option>
                  <option value="23">11 PM</option>
                </select>
              </div>

              <div className="minute-container">
                <label htmlFor="minute">Hour*</label>
                <select {...register("minute", { required: true })}>
                  <option value="0">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </select>
              </div>
            </div>
          </div>
          <div className="error-field">{errors.ride_date && <p>{errors.ride_date.message}</p>}</div>

          <div className="input-field">
            <label htmlFor="passenger">Passengers*</label>
            <input
              type="text"
              placeholder="Passengers"
              {...register("passenger", {
                required: "Passenger is required.",
                maxLength: {
                  value: 2,
                  message: "Max length is 2",
                },
              })}
            />
          </div>
          <div className="error-field">{errors.passenger && <p>{errors.passenger.message}</p>}</div>

          <div className="input-field">
            <label htmlFor="contact_name">CONTACT NAME*</label>
            <input
              type="text"
              placeholder=""
              {...register("contact_name", {
                required: "Contact name is required.",
                maxLength: {
                  value: 50,
                  message: "Max length is 50",
                },
              })}
            />
          </div>
          <div className="error-field">{errors.contact_name && <p>{errors.contact_name.message}</p>}</div>

          <div className="input-field">
            <label htmlFor="contact_email">Contact Email*</label>
            <input
              type="text"
              placeholder=""
              {...register("contact_email", {
                required: "Contact email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address.",
                },
              })}
            />
          </div>
          <div className="error-field">{errors.contact_email && <p>{errors.contact_email.message}</p>}</div>

          <div className="input-field">
            <label className="input-label" htmlFor="contact_phone">
              contact phone number*
            </label>
            <input
              type="text"
              placeholder=""
              {...register("contact_phone", {
                required: "Contact Phone number is required.",
                minLength: {
                  value: 12,
                  message: "Phone number should be 10 digits.",
                },
              })}
              onChange={formatPhoneNumber}
            />
          </div>
          <div className="error-field">{errors.contact_phone && <p>{errors.contact_phone.message}</p>}</div>

          <div className="form-item google-recaptcha">
            <ReCAPTCHA
              {...register("g-recaptcha-response", { required: "Please select the captcha." })}
              name="g-recaptcha-response"
              sitekey="6LcpIdkjAAAAAC6dHQe1pgDZ-TTHiXjh6mm-85qg"
              onChange={handleSetRecaptcha}
            />
          </div>
          <div className="error-field">{errors["g-recaptcha-response"] && <p>{errors["g-recaptcha-response"].message}</p>}</div>

          <div className="submit-container">
            <input type="submit" value="Submit Request!" />
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchForm;
