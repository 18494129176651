import React from "react";
import "./Container.scss";

const Container = (props) => {
  const { style, className, children } = props;
  return (
    <div className={`bid-container ${className}`} style={style}>
      {children}
    </div>
  );
};
export default Container;
