import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-component">
      <div className="horizontal-line"></div>
      <div className="footer-text">
        <span>
          Call 415.993.5075 or email support@bidmyride.com for assistance.
        </span>
      </div>
    </div>
  );
};

export default Footer;
