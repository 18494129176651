import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const [leftDuration, setLeftDuration] = useState({
    days: targetDate?.days || 0,
    hour: targetDate?.hours || 0,
    min: targetDate?.minutes || 0,
    sec: targetDate?.seconds || 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { days, hour, min, sec } = leftDuration;

      if (sec != 0) {
        setLeftDuration({
          ...leftDuration,
          sec: `${sec <= 10 ? "0" : ""}${sec - 1}`,
        });
      } else if (min != 0 && sec == 0) {
        setLeftDuration({
          ...leftDuration,
          sec: 59,
          min: `${min <= 10 ? "0" : ""}${min - 1}`,
        });
      } else if (hour != 0 && min == 0) {
        setLeftDuration({
          ...leftDuration,
          min: 60,
          hour: `${hour <= 10 ? "0" : ""}${hour - 1}`,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [leftDuration]);

  return getReturnValues(leftDuration);
};

const getReturnValues = (leftDuration) => {
  if (leftDuration.hour > 23) {
    const quotient = ~~(leftDuration.hour / 24);
    const reminder = leftDuration.hour % 24;
    const days = leftDuration.days + quotient;
    const hours = leftDuration.hour - (leftDuration.hour / 24) * 24 + reminder;
    const minutes = leftDuration.min;
    const seconds = leftDuration.sec;
    return [days, hours, minutes, seconds];
  } else {
    const days = leftDuration.days;
    const hours = leftDuration.hour;
    const minutes = leftDuration.min;
    const seconds = leftDuration.sec;
    return [days, hours, minutes, seconds];
  }
};

export { useCountdown };
