import React from "react";
import "./Form.scss";

const Form = (props) => {
  return (
    <div className={props.setClass}>
      <form
        className={props.className}
        onSubmit={props.onSubmit}
        style={props.style}
      >
        {props.children}
      </form>
    </div>
  );
};

export default Form;
