import React from "react";
import "./AboutSteps.scss";
import { SquareBox } from "../../Layouts";

const AboutSteps = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`bid-about-steps ${className}`} style={style}>
      <SquareBox
        url="https://assets.s3.amazonaws.com/images/front-end/new-landing/Industry-experience.png"
        desc="Years of Industry Experience"
      />
      <SquareBox
        url="https://assets.s3.amazonaws.com/images/front-end/new-landing/easy-bid-process.png"
        desc="Easy 3-step process"
      />
      <SquareBox
        url="https://assets.s3.amazonaws.com/images/front-end/new-landing/verified-operators.png"
        desc="Join verified Operators globally"
      />
      <SquareBox
        url="https://assets.s3.amazonaws.com/images/front-end/new-landing/get-paid-fast.png"
        desc="Get Paid fast"
      />
    </div>
  );
};

export default AboutSteps;
